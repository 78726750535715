import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import styles from './contact.module.scss'

export default ({ location }) => (
  <Layout location={location}>
    <Helmet>
      <title>Contact us</title>
    </Helmet>
    <iframe
      id='typeform-full'
      title='Contact Form'
      className={styles.Iframe}
      width='100%'
      height='100%'
      frameBorder='0'
      src='https://zero-technology.typeform.com/to/r43f5o'
    />
    <script type='text/javascript' src='https://embed.typeform.com/embed.js' />
  </Layout>
)
